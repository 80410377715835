@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;  
}
body{
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}
.Dashboard-header{
    width: 100%;
    height: 8%;
    background-color: #DB324A;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: large;
}
.topo-left{
    width: 15%;
    /* background-color: aqua; */
}
.topo-center{
    width: 65%;
    padding-left: 4%;
    display: flex;
}
.topo-right{
    width: 20%;
}
.topo-right button{
    width: 160px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #000000; 
}
.button-chamado{
    border-radius: 5px;
    background-color: #ffffff;
}
/* Fim do header */

.Dashboard-main{
    width: 100%;
    height: 92%;
}
.main-esq{
    width: 15%;
    height: 100%;
    background-color: #DB324A;
    display: block;
    color: #ffffff;
}
.main-esq-usuario{
    width: 100%;
    height: 27%;
    /* background-color: #dbc3c7; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.main-esq-usuario img{
    width: 75px;
    height: 75px;
}
.main-esq-usuario-perfil{
    width: 100%;
    height: 8%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
}
.perfil-acess{
    color: #ffffff;
    font-weight: bold;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}
.main-esq-setor1{
    width: 100%;
    height: 6%;
    /* background-color: blueviolet; */
    padding-left: 25px;
}
.main-esq-setor2{
    width: 100%;
    height: 29%;
    /* background-color: #fa7b8e; */
    display: flex;
    flex-direction: column;
    padding-left: 25px;
}
.main-esq-setor2 label{
    font-weight: bold;
}
.main-esq-setor2 input{
    border-radius: 5px;
    /* width: 160px; */
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}
.main-esq-sair{
    width: 100%;
    height: 30%;
    /* background-color: #1ea840; */
    display: flex;
    align-items: center;
}
.main-esq-sair{
    padding-left: 25px;
}
.botao-sair{
    color: #ffffff;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    font-size: 25px;
    font-weight: bold;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;  
}
body{
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}
.Perfil-header{
    width: 100%;
    height: 8%;
    background-color: #DB324A;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: large;
}
.top-left{
    width: 15%;
}
.top-right{
    width: 85%;
    padding-left: 4%;
}
/* fim do header */

.Perfil-main{
    width: 100%;
    height: 92%;
    display: flex;
}
.main-left{
    width: 15%;
    background-color: #DB324A;
    display: block;
    justify-content: flex-end;
    padding-top: 36.5%;
    color: #ffffff;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    font-size: 25px;
    font-weight: bold;
    padding-left: 25px;
}
.main-right{
    width: 85%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
}
.main-central{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-central-picture{
    width: 100%;
    height: 30%;
    /* background-color: blanchedalmond; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-central-picture img{
    width: 150px;
    height: 150px;
}
.main-central-name{
    width: 100%;
    height: 10%;
    /* background-color: rgb(36, 93, 100); */
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-central-name h1{
    color: #00000050;
}
.main-central-info{
    width: 100%;
    height: 40%;
    /* background-color: gray; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15%;
}
.main-central-info p{
    font-weight: bold;
    color: #000000;
}
.main-central-info input{
    width: 100%;
    height: 40px;
    border: 2px solid #00000070;
    border-radius: 5px;
}
.main-central-back{
    width: 100%;
    height: 7%;
    /* background-color: rgb(199, 172, 136); */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.botao-voltar{
    text-decoration: none;
    color: #DB324A;
    font-weight: bolder;
    font-size: 30px;
}

*{
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
.main-cd{
    background-color:white;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.barraLateral-cd{
    background-color:#DB324A;
    height: 100vh;
    width: 80%;
}
.letra-lg{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
    color:white;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    display: flex;
    padding-top: 50%;
    display: flex;
    justify-content: center;
}
.section-cd{
     background-color:white; 
    height: 100vh;
    width: 93%;
    display: flex;
    flex-direction: column;
}
.header-cd{
    height: 25vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
    color: #DB324A;
}
.header-cd p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 50px;
    color:#DB324A;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    background-color:white;
    width: 70%;
}
.login-cd{
    height: 70vh;
    width: 90%;
    margin: auto;
    /* background-color: rgb(223, 22, 22); */
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    background-color: white;
}
.meio0-cd{
    height: 100%;
    width: 45%;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:white;
}
.meio1-cd{
    height: 100%;
    width: 10%;
    background-color: white; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.linha-cd{
    border-left: 3px solid black;
    height: 30%;
    background-color:white;
    
}
.meio2-cd{
    height: 100%;
    width: 60%;
    background-color:white; 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}
.forms-cd{
    width: 100%;
    height: 70%;
    background-color: white;
}
.form-cd{
    margin-top: 20px;
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color:white;
}
.metadeForm-cd{
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color:white;
}
.input-cd{
    background-color: white;
    border-bottom: solid black 1px;
    margin-top: 10px;
    width: 80%;
    height: 33%;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 36px;
    background-color:white;
}
.input-cd:-ms-input-placeholder {
    text-transform: capitalize;
    color: gray;
    opacity: 1;
}
.input-cd::placeholder {
    text-transform: capitalize;
    color: gray;
    opacity: 1;
}
.btn-cd{
    height: 30%;
    width: 100%;
    padding-top: 25px;
  /*  background-color: yellowgreen; */ 
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.button-cd{
    width: 80%;
    height: 70%;
    background-color: #DB324A;
    border-radius: 82px;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    transition: 0.5s;
}
.button-cd:hover{
    color:white;
    background-color:black;
}
*{
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
.main-lg{
    background-color:white;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.barraLateral-lg{
    background-color:#DB324A;
    height: 100vh;
    width: 80%;
}
.letra-lg{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
    color:white;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    display: flex;
    padding-top: 50%;
    display: flex;
    justify-content: center;
}
section{
    /* background-color: rebeccapurple; */
    height: 100vh;
    width: 93%;
    display: flex;
    flex-direction: column;
}
.header-lg{
    height: 30vh;
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
}
.header-lg p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 50px;
    color:#DB324A;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    background-color:white;
    width: 70%;
}
.login-lg{
    height: 80vh;
    width: 90%;
    margin: auto;
    /* background-color: rgb(223, 22, 22); */
    margin-top: px;
    display: flex;
    flex-direction: row;
    background-color:white;
}
.meio0-lg{
    height: 100%;
    width: 45%;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;   
}
.meio0-lg img{
    width: 90%;
    height: 90%;
}
.meio1-lg{
    height: 100%;
    width: 10%;
    /* background-color: blueviolet; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.linha-lg{
    border-left: 3px solid black;
    height: 30%;
}
.meio2-lg{
    height: 100%;
    width: 60%;
     background-color:white; 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}
.forms-lg{
    width: 100%;
    height: 70%;
    /* background-color: var(--verde1); */
}
form{
    margin-top: 20px;
    height: 70%;
    width: 100%;
    /* background-color: brown; */
    display: flex;
    align-items: center;
    flex-direction: column;
}
.metadeForm-lg{
    width: 100%;
    height: 55%;
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.input-lg{
    background-color:  #FFFEE8;
    border-bottom: solid black 1px;
    width: 80%;
    height: 38%;
    text-align: center;
    font-family: Josefin Sans;
    color: black;
    font-weight: bold;
    font-size: 36px;
}
.input-lg:-ms-input-placeholder{
    text-transform: capitalize;
    color:gray;
    opacity: 1;
}
.input-lg::placeholder{
    text-transform: capitalize;
    color:gray;
    opacity: 1;
}
.btn-lg{
    height: 30%;
    width: 100%;
    padding-top: 20px;
  /*  background-color: yellowgreen; */ 
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.btn-lg button{
    width: 80%;
    height: 70%;
    background-color: #DB324A;
    border-radius: 82px;
    font-size: 35px;
    font-family: Rajdhani;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    transition: 0.5s;
}
.btn-lg button:hover{
    color: white;
    background-color: black;
}
.btn-p-lg{
    color: #DB324A;
    font-size: 20px;
    font-family: Rajdhani;
}
.msm-cad-lg{
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.msm-p-lg{
    font-size: 20px;
    font-family: Josefin Sans;
    font-weight: bold;  
    color: black;
}
.msm-p2-lg{
    font-size: 20px;
    font-family: Josefin Sans;
    font-weight: bold;
    text-decoration: none;
    color: blue;
}
