@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
.main-cd{
    background-color:white;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.barraLateral-cd{
    background-color:#DB324A;
    height: 100vh;
    width: 80%;
}
.letra-lg{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
    color:white;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    display: flex;
    padding-top: 50%;
    display: flex;
    justify-content: center;
}
.section-cd{
     background-color:white; 
    height: 100vh;
    width: 93%;
    display: flex;
    flex-direction: column;
}
.header-cd{
    height: 25vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
    color: #DB324A;
}
.header-cd p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 50px;
    color:#DB324A;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    background-color:white;
    width: 70%;
}
.login-cd{
    height: 70vh;
    width: 90%;
    margin: auto;
    /* background-color: rgb(223, 22, 22); */
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    background-color: white;
}
.meio0-cd{
    height: 100%;
    width: 45%;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:white;
}
.meio1-cd{
    height: 100%;
    width: 10%;
    background-color: white; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.linha-cd{
    border-left: 3px solid black;
    height: 30%;
    background-color:white;
    
}
.meio2-cd{
    height: 100%;
    width: 60%;
    background-color:white; 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}
.forms-cd{
    width: 100%;
    height: 70%;
    background-color: white;
}
.form-cd{
    margin-top: 20px;
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color:white;
}
.metadeForm-cd{
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color:white;
}
.input-cd{
    background-color: white;
    border-bottom: solid black 1px;
    margin-top: 10px;
    width: 80%;
    height: 33%;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 36px;
    background-color:white;
}
.input-cd::placeholder {
    text-transform: capitalize;
    color: gray;
    opacity: 1;
}
.btn-cd{
    height: 30%;
    width: 100%;
    padding-top: 25px;
  /*  background-color: yellowgreen; */ 
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.button-cd{
    width: 80%;
    height: 70%;
    background-color: #DB324A;
    border-radius: 82px;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    transition: 0.5s;
}
.button-cd:hover{
    color:white;
    background-color:black;
}