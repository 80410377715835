@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

*{
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
.main-lg{
    background-color:white;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.barraLateral-lg{
    background-color:#DB324A;
    height: 100vh;
    width: 80%;
}
.letra-lg{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
    color:white;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    display: flex;
    padding-top: 50%;
    display: flex;
    justify-content: center;
}
section{
    /* background-color: rebeccapurple; */
    height: 100vh;
    width: 93%;
    display: flex;
    flex-direction: column;
}
.header-lg{
    height: 30vh;
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
}
.header-lg p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 50px;
    color:#DB324A;
    text-transform: uppercase;
    font-weight: bolder;
    margin: none;
    background-color:white;
    width: 70%;
}
.login-lg{
    height: 80vh;
    width: 90%;
    margin: auto;
    /* background-color: rgb(223, 22, 22); */
    margin-top: px;
    display: flex;
    flex-direction: row;
    background-color:white;
}
.meio0-lg{
    height: 100%;
    width: 45%;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;   
}
.meio0-lg img{
    width: 90%;
    height: 90%;
}
.meio1-lg{
    height: 100%;
    width: 10%;
    /* background-color: blueviolet; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.linha-lg{
    border-left: 3px solid black;
    height: 30%;
}
.meio2-lg{
    height: 100%;
    width: 60%;
     background-color:white; 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}
.forms-lg{
    width: 100%;
    height: 70%;
    /* background-color: var(--verde1); */
}
form{
    margin-top: 20px;
    height: 70%;
    width: 100%;
    /* background-color: brown; */
    display: flex;
    align-items: center;
    flex-direction: column;
}
.metadeForm-lg{
    width: 100%;
    height: 55%;
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.input-lg{
    background-color:  #FFFEE8;
    border-bottom: solid black 1px;
    width: 80%;
    height: 38%;
    text-align: center;
    font-family: Josefin Sans;
    color: black;
    font-weight: bold;
    font-size: 36px;
}
.input-lg::placeholder{
    text-transform: capitalize;
    color:gray;
    opacity: 1;
}
.btn-lg{
    height: 30%;
    width: 100%;
    padding-top: 20px;
  /*  background-color: yellowgreen; */ 
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.btn-lg button{
    width: 80%;
    height: 70%;
    background-color: #DB324A;
    border-radius: 82px;
    font-size: 35px;
    font-family: Rajdhani;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    transition: 0.5s;
}
.btn-lg button:hover{
    color: white;
    background-color: black;
}
.btn-p-lg{
    color: #DB324A;
    font-size: 20px;
    font-family: Rajdhani;
}
.msm-cad-lg{
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.msm-p-lg{
    font-size: 20px;
    font-family: Josefin Sans;
    font-weight: bold;  
    color: black;
}
.msm-p2-lg{
    font-size: 20px;
    font-family: Josefin Sans;
    font-weight: bold;
    text-decoration: none;
    color: blue;
}