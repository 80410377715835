@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;  
}
body{
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}
.Dashboard-header{
    width: 100%;
    height: 8%;
    background-color: #DB324A;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: large;
}
.topo-left{
    width: 15%;
    /* background-color: aqua; */
}
.topo-center{
    width: 65%;
    padding-left: 4%;
    display: flex;
}
.topo-right{
    width: 20%;
}
.topo-right button{
    width: 160px;
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #000000; 
}
.button-chamado{
    border-radius: 5px;
    background-color: #ffffff;
}
/* Fim do header */

.Dashboard-main{
    width: 100%;
    height: 92%;
}
.main-esq{
    width: 15%;
    height: 100%;
    background-color: #DB324A;
    display: block;
    color: #ffffff;
}
.main-esq-usuario{
    width: 100%;
    height: 27%;
    /* background-color: #dbc3c7; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.main-esq-usuario img{
    width: 75px;
    height: 75px;
}
.main-esq-usuario-perfil{
    width: 100%;
    height: 8%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
}
.perfil-acess{
    color: #ffffff;
    font-weight: bold;
    text-decoration-line: none;
}
.main-esq-setor1{
    width: 100%;
    height: 6%;
    /* background-color: blueviolet; */
    padding-left: 25px;
}
.main-esq-setor2{
    width: 100%;
    height: 29%;
    /* background-color: #fa7b8e; */
    display: flex;
    flex-direction: column;
    padding-left: 25px;
}
.main-esq-setor2 label{
    font-weight: bold;
}
.main-esq-setor2 input{
    border-radius: 5px;
    /* width: 160px; */
    height: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}
.main-esq-sair{
    width: 100%;
    height: 30%;
    /* background-color: #1ea840; */
    display: flex;
    align-items: center;
}
.main-esq-sair{
    padding-left: 25px;
}
.botao-sair{
    color: #ffffff;
    text-decoration-line: none;
    font-size: 25px;
    font-weight: bold;
}