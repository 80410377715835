@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;  
}
body{
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}
.Perfil-header{
    width: 100%;
    height: 8%;
    background-color: #DB324A;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: large;
}
.top-left{
    width: 15%;
}
.top-right{
    width: 85%;
    padding-left: 4%;
}
/* fim do header */

.Perfil-main{
    width: 100%;
    height: 92%;
    display: flex;
}
.main-left{
    width: 15%;
    background-color: #DB324A;
    display: block;
    justify-content: flex-end;
    padding-top: 36.5%;
    color: #ffffff;
    text-decoration-line: none;
    font-size: 25px;
    font-weight: bold;
    padding-left: 25px;
}
.main-right{
    width: 85%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
}
.main-central{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-central-picture{
    width: 100%;
    height: 30%;
    /* background-color: blanchedalmond; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-central-picture img{
    width: 150px;
    height: 150px;
}
.main-central-name{
    width: 100%;
    height: 10%;
    /* background-color: rgb(36, 93, 100); */
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-central-name h1{
    color: #00000050;
}
.main-central-info{
    width: 100%;
    height: 40%;
    /* background-color: gray; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15%;
}
.main-central-info p{
    font-weight: bold;
    color: #000000;
}
.main-central-info input{
    width: 100%;
    height: 40px;
    border: 2px solid #00000070;
    border-radius: 5px;
}
.main-central-back{
    width: 100%;
    height: 7%;
    /* background-color: rgb(199, 172, 136); */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.botao-voltar{
    text-decoration: none;
    color: #DB324A;
    font-weight: bolder;
    font-size: 30px;
}
